// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dictionary = () => {
  return {
    SEARCH_LOADING: 'جاري البحث',
    GENERAL_DEFINTIONS: 'تعريفات عامة',
    USER_DEFINTION: 'تعريف',
    DATA: 'البيانات',
    DATA_ANALYSIS: 'تحليل البيانات',
    MENU: 'القائمة',
    ADMIN_LOGIN: 'تسجيل الدخول للمشرفين',
    LOGIN_BACK_LINK: 'العودة إلى الإختيارات',
    STATION_LOGIN: 'تسجيل الدخول للمحطات',
    STATION_PROVIDER_LOGIN: 'تسجيل الدخول لشبكات المحطات',
    CORPORATE_LOGIN: 'تسجيل الدخول للشركات',
    CORPORATE_GROUP_LOGIN: 'تسجيل الدخول لمجموعة شركات',
    ACTIONS: 'الإجراءات',
    ADD_NEW: 'إضافة جديد',
    DELETE: 'حذف',
    OF: 'من',
    IN: 'في',
    PASSWORD: 'كلمة المرور',
    PROFILE: 'الملف الشخصي',
    LOGOUT: 'تسجيل لخروج',
    DASHBOARD: 'لوحة التحكم',
    ADMINS: 'مسؤولون',
    DEFAULT_CONFIRM_TITLE: 'هل انت متاكد؟',
    DEFAULT_CONFIRM_DESCRIPTION: 'هل انت متأكد ؟ لا يمكنك التراجع عن هذا الإجراء',
    CONFIRM_BUTTON: 'موافق',
    DEFAULT_CONFIRM_CONFIRMATION_TEXT: 'موافق',
    DEFAULT_CONFIRM_CANCELLATION_TEXT: 'الغاء',
    USERNAME: 'اسم المستخدم',
    INCORRECT_USERNAME: 'اسم مستخدم غير صحيح',
    SEND_PIN: ' ارسال رمز التحقق',
    SEND_PIN_DESCRIPTION: 'سوف يتم ارسال رمز تححق إلى رقم الهاتف الملحق بالحساب الخاص باسم المستخدم',
    LOGIN: 'تسجيل الدخول',
    LOGGINGIN: 'جاري تسجيل الدخول',
    FORGOT_PASSWORD_LINK: 'هل نسيت كلمة المرور ؟',
    RESET_PASSWORD: 'استعادة كلمة المرور',
    RESET_PASSWORD_DESCRIPTION: 'ادخل كلمة المرور الجديدة',
    VERIFY_PIN: 'تأكيد رمز التحقق',
    VERIFY_PIN_DESCRIPTION: 'ادخل رمز التحقق المرسل إلى هاتفك لاستعادة كلمة المرور',
    RESEND_PIN: ' اعادة ارسال رمز التحقق ',
    ALL: 'الكل',
    DEFAULT: 'الإفتراضي',
    STATEMENT: 'كشف الحساب',
    TRANSACTION_DATE: 'تاريخ المعاملة',
    TOLL_TRANSACTIONS_DETAILS: 'تفاصيل مصروفات البوابات',
    FUEL_TRANSACTIONS_DETAILS: 'تفاصيل مصروفات الوقود',
    DEBIT_BALANCE: 'ايداع',
    CREDIT_BALANCE: 'سحب',
    NEWEST: 'الأحدث',
    OLDEST: 'الأقدم',
    PHONE: 'الهاتف',
    EMAIL: 'البريد الإلكتروني',
    INITIAL_CONFIRMATION: 'تأكيد مبدئي',
    INITIAL_REJECTION: 'رفض مبدئي',
    THE_DEPOSIT_REF_IS_DUPLICATE: 'هذا الرقم مكرر في عملية إيداع أخرى.',
    FINAL_CONFIRMATION: 'تأكيد نهائي',
    REJECTED: 'مرفوض',
    VIEW_DETAILS: 'عرض التفاصيل',
    REQUIRED: 'هذا الحقل مطلوب',
    ERROR: 'حدث خطأ',
    NOTFOUND: 'لم يتم العثور على البيانات',
    SUCCESS: 'نجاح',
    NAME: 'الإسم',
    SUPER_ADMIN: 'مسؤول عام',
    FAILED: 'فشل',
    EDIT: 'تعديل',
    ADD_DEPSOIT: 'إضافة رصيد',
    ADD: 'إضافة',
    PAYMENTS: 'المدفوعات',
    REJECT: 'رفض',
    BACK: 'رجوع',
    DONE: 'تم',
    OTHER: 'غير ذلك',
    PICTURE_NOT_CLEAR: 'صورة غير واضحة',
    REASON_DISCREPTION: 'سبب وتفاصيل الرفض',
    DEPOSIT_REF: 'رقم الإيداع',
    DEPOSIT_DATE: 'تاريخ الإيداع',
    SAVE: 'حفظ',
    ADMIN: 'مسؤول',
    EDIT_ADMIN: 'تعديل مسؤول',
    IMAGE: 'صورة',
    NO_RESULTS_FOUND: 'لا يوجد نتائج',
    ADD_ADMIN: ' إضافة مسؤول',
    FIRST_NAME: 'الإسم الأول',
    LAST_NAME: 'الإسم الأخير',
    FULL_NAME: 'الإسم الكامل',
    TITLE: 'العنوان',
    ADMIN_TYPE: 'نوع المسؤول',
    CREATED_AT: 'تاريخ الإنشاء',
    PIN: 'رمز التحقق',
    USERS: 'المستخدمون',
    USER: 'مستخدم',
    USERINFO: 'معلومات المستخدم',
    PERSONAL_INFO: 'معلومات عامة',
    TRANSACTIONS_INFO: 'معاملات',
    VALIFYRESULTS: 'التحقق من النتائج',
    NUMBER_PLATE: 'رقم اللوحة',
    CHASSIS_NUMBER: 'رقم الشاسيه',
    VEHICLE_TYPE: 'نوع المركبة',
    CAR_MODEL: 'الموديل',
    CAR_BRAND: 'العلامة التجارية',
    CAR_COLOR: 'لون المركبة',
    MAX_FUEL_LITERS: '(لتر) أقصى سعة للوقود',
    LITRES_AMOUNT: 'سعة المعاملة باللترات',
    LITRES: 'لتر',
    FUEL_TYPES: 'نوع الوقود',
    VEHICLES: 'المركبات',
    CAR_ID: 'رقم المركبة',
    NEXTACARDS: 'Nexta Cards',
    EXTERNALCARDS: 'البطاقات الخارجية',
    CHOOSE_STATION_PROVIDER: 'اختار شبكة المحطات',
    TOPUPS: 'الشحن',
    ADDRESS: 'العنوان',
    UPDATED_AT: ' تاريخ التعديل',
    DATE_OF_BIRTH: 'تاريخ الميلاد',
    AREA: 'المنطقة',
    CONFIDENCE: 'الثقة',
    MARTIAL_STATUS: 'الحالة الإجتماعية',
    EXPIRED: 'منتهي',
    EXPIRED_DATE: 'تاريخ الإنتهاء',
    FRONT_DATA_VALIDITY: 'صلاحية البيانات',
    FRONT_NID: 'Front Nid',
    HUSBAND_NAME: 'اسم الزوجة',
    PROFESSION: 'المهنة',
    RELEASE_DATE: 'تاريخ الإصدار',
    RELIGION: 'الديانة',
    THE_: 'رقم المسلسل',
    STREET: 'الشارع',
    CARD_NAME: 'اسم البطاقة',
    HOLDER_NAME: 'اسم المستخدم',
    STATUS: 'الحالة',
    NEXTA_CARD: 'Nexta Card',
    CARD_INFO: 'معلومات البطاقة',
    MASKED_PAN: 'Masked Pan',
    HAS_PHYSICAL: 'Has Physical',
    HAS_PHYSICAL_ORDER: 'Has Physical Order',
    IS_PIN_SET: 'Pin Set',
    START_DATE: 'تاريخ البدء',
    VALID: 'صالح',
    NOTVALID: 'غير صالح',
    YES: 'نعم',
    NO: 'لا',
    BRAND: 'العلامة التجارية',
    PRIORITY: 'الأولوية',
    AMOUNT: 'المبلغ',
    TOTAL_AMOUNT: 'المبلغ الكلي',
    DAY: 'اليوم',
    SCHEDULED_TOP_UP: 'الشحن المجدول',
    TOP_UP_INFO: 'معلومات الشحن',
    ACTIVE: 'مفعل',
    EXTERNAL_CARD: 'بطاقة خارجية',
    TRANSACTIONS: 'المعاملات',
    TOLL_TRANSACTIONS: 'معاملات البوابات',
    FUEL_TRANSACTIONS: 'معاملات الوقود',
    TYPE: 'النوع',
    TRANSACTION: 'المعاملة',
    DESCRIPTION: 'الوصف',
    TRANSACTION_INFO: 'معلومات المعاملة',
    CARDS: 'البطاقات',
    USER_TRANSACTION: 'معاملات المستخدم',
    PAYMENT_REFERENCE: 'رقم المرجع',
    NATIONAL_ID: 'الرقم القومي',
    FAWRY_BILL_TRANSACTION: 'فواتير معاملات فوري ',
    FAWRY_CASH_IN_TRANSACTION: 'lu',
    GEIDEA_TRANSACTION: 'Geidea معاملات  ',
    GPS_TRANSACTION: 'GPS معاملات ',
    BILL_REFERENCE_NUMBER: 'رقم الفاتورة المرجعي',
    BILL_TYPE_CODE: 'رقم الفاتورة النوعي',
    NOTIFY_MOBILE: 'إخطار الهاتف المحمول',
    EXTERNAL_STATUS: 'الحالة الخارجية',
    CURRENCY: 'العملة',
    DETAILED_STATUS: 'الحالة التفصيلية',
    RESPONSE_CODE: 'رمز الإستجابة',
    DETAILED_RESPONSE_CODE: 'رمز الإستجابة التفصيلي',
    PAYMENT_OPERATION: 'عملية الدفع',
    DIRECTION: 'الإتجاه',
    USERS_TRANSACTION: 'معاملات المستخدم',
    PERSONS: 'الأشخاص',
    SORT_BY: 'ترتيب حسب',
    SCHEDULED_TOP_UPS: 'الشحن المجدول',
    META: 'Meta',
    AUTHORIZATION_TOKENS: 'رموز الموافقة',
    IS_ENABLED: 'متاح',
    ADD_AUTHORIZATION_TOKEN: 'إضافة رمز الموثوفية',
    ALLOWED_ROUTES: 'الطرق المسموح بها',
    AUTHORIZATION_TOKEN: 'رمز الموثوقية',
    EDIT_AUTHORIZATION_TOKEN: 'تعديل رمز الموثوقية',
    TOKEN_MESSAGE: ' هذه هي المرة الوحيدة اللتي سيظهر بها الرمز , يجب حفظه في مكان امن قبل اغلاق النافذة',
    VEHICLE_TYPES: 'أنواع المركبات',
    CORPORATES_NAME: 'اسم الشركة',
    POS_SERIAL_NUMBER: 'رقم تسلسلي لجهاز نقاط البيع',
    AMOUNT_FEES: 'ميلغ الرسوم',
    AMOUNT_TOTAL: 'المبلغ الإجمالي',
    IMAGES: 'الصور',
    CODE: 'كود',
    STATIONS_CODE: 'كود المحطة',
    STATIONS_NAME: 'اسم المحطة',
    STATIONS_TYPE: 'نوع المحطة',
    STATIONS_CONTACT_NAME: 'اسم المسئول',
    STATIONS_CONTACT_NUMBER: 'رقم المسئول',
    STATIONS_CONTACT_EMAIL: 'البريد الإلكتروني للمسئول',
    CHOOSE_STATION_PLACEHOLDER: 'اختر المحطة ...',
    LONGITUDE: 'خط الطول',
    LATITUDE: 'خط العرض',
    VEHICLE_TYPE_NAME: 'اسم نوع المركبة',
    DIMENSIONS: 'الأبعاد',
    VOLUME: 'السعة',
    TOLL_AMOUNT: 'رسوم البوابات',
    MAX_WEIGHT: 'اقصى وزن مسموح',
    PRODUCT_TYPES: 'أنواع المنتجات',
    PRODUCT_NAME: 'أسم المنتج',
    DENSITY: 'الكثافة',
    WEIGHT_COST: 'سعر الوزن',
    TRIPS: 'الرحلات',
    DRIVER_NAME: 'اسم السائق',
    TRIP_ID: 'رقم الرحلة',
    PRODUCT_TYPE: 'نوع المنتج',
    FUEL: 'البنزين',
    TOLL: 'البوابات',
    STORE: 'المتجر',
    WEIGHT: 'الوزن',
    CAPACITY: 'نسبة الحمولة',
    CITY: 'المحافظة',
    STARTING_POINT: 'نقطة البداية',
    DESTINATION: 'الوجهة',
    TRANSACTION_ID: 'رقم المعاملة',
    STATION_NAME: 'اسم المحطة',
    WEIGHT_FEES: 'رسوم الوزن',
    FINE_FEES: 'غرامات',
    DRIVERS: 'السائقين',
    DRIVER_ID: 'رقم السائق',
    DRIVER_CODE: 'كود السائق',
    DRIVER_PHONE: 'رقم الهاتف',
    DRIVER_STATUS: 'حالة السائق',
    DRIVER_BALANCE: 'الرصيد',
    DRIVER_USER_NAME: 'اسم المستخدم',
    DRIVER_TYPE: 'نوع السائق',
    CORPORATE: 'الشركة',
    DRIVER_VERIFICATION: 'نوع التأكيد',
    QR_CODE: 'QR Code',
    OFFLINE_PIN: 'Offline Pin',
    PRICING: 'التعريفات',
    RATE: 'التعريفة',
    PRICING_CURRENCY: ' ج.م.',
    SEARCH: ' بحث ...',
    SEARCH_WITH_NAME_OR_ID: 'بحث باسم او الرقم التعريفى ...',
    CHOOSE_CORPORATE_PLACEHOLDER: 'اختر شركه ...',
    STARTING_POINT_NAME: 'اسم النقطة المبدئية',
    STATIONS: 'المحطات',
    PRODUCT: 'المنتج',
    ALLOW_FUEL: 'الوقود',
    DRIVER: 'السائق',
    ADD_NEW_TRIP: 'إضافة رحلة جديدة',
    ADD_NEW_VEHICLE: 'إضافة مركبة جديدة',
    ADD_NEW_ADMIN: 'إضافة مسؤول جديد',
    DATE: 'التاريخ',
    TIME: 'الوقت',
    DATE_AND_TIME: 'التاريخ والوقت',
    THIS_PLATE_NUMBER_IS_ALREADY_OCCURED: 'رقم اللوحة مستخدم مسبقا',
    THIS_CODE_IS_ALREADY_TAKEN: 'هذا الكود مستخدم مسبقا',
    FROM: 'من',
    TO: 'إلى',
    TOTAL_INCOME: 'إجمالي الإرادات',
    TOTAL_TRANSACTION_COUNT: 'إجمالي عدد العمليات',
    TOTAL_CORPORATE_COUNT: 'إجمالي عدد الشركات',
    TOTAL_VEHICLE_COUNT: 'إجمالي عدد السيارات',
    AVERAGE_TRANSACTIONS_NUMBER_PER_DAY: 'متوسط عدد العمليات في اليوم',
    INCOME_DETAILS: 'تفصيل الإرادات',
    CONFIRMED: 'مؤكدة',
    CONFIRM_IT: 'تأكيد',
    PENDING: 'غير مؤكدة',
    CANCELED: 'ملغية',
    CANCEL: 'الغاء',
    TON: 'طن',
    CUBIC_METER: 'م٣',
    FUEL_ID: 'رقم الوقود',
    FUEL_NAME: 'اسم الوقود',
    PRICE: 'السعر',
    STATION: 'المحطة',
    CORPORATES: 'الشركات',
    VEHICLE_ID: 'رقم المركبة ',
    VEHICLE_CODE: 'كود المركبة',
    TIER: 'التقييم',
    SETTLEMENT_TYPE: 'نوع التسوية',
    FINANCIAL_CACLULATIONS_DETAILS: ' تفاصيل المعاملات المالية :',
    FINANCIAL_CACLULATIONS_ERR: ' خطأ في تفاصيل المعاملات المالية لايمكن استخدام هذا المنتج مع الوزن و نوع المركبة المختاران ',
    THIS_INPUT_CANT_EQUAL_TO_ZERO: 'هذه القيمة يجب ان تكون اكبر من الصفر',
    THIS_INPUT_CANT_BE_BELOW_ZERO: 'هذه القيمة يجب ان تكون صفر او اكبر',
    ADD_NEW_CORPORATE: 'إضافة شركة جديدة',
    HIDE: 'اخفاء',
    SHOW: 'اظهار',
    ADD_NEW_STATION: 'إضافة محطة جديدة',
    STATION_ROAD: 'شارع المحطة',
    STATION_ADDRESS: 'عنوان المحطة',
    STATION_PROVIDER: 'شبكة المحطات',
    STATION_LATITUDE: 'موقع المحطة: خط العرض',
    STATION_LONGITUDE: 'موقع المحطة: خط الطول',
    STATION_NAME_AR: 'اسم المحطة باللغة العربية',
    STATION_NAME_EN: 'اسم المحطة باللغة الإنجليزية',
    THIS_CODE_IS_ALREADY_OCCURED: 'هذا الكود مستخدم بالفعل',
    STATION_PROVIDERS: 'شبكات المحطات ',
    STATION_PROVIDERS_NAME_AR: ' اسم شبكة المحطات باللغة العربية',
    STATION_PROVIDERS_NAME_EN: ' اسم شبكة المحطات باللغة الإنجليزية ',
    STATION_PROVIDERS_NAME: ' اسم شبكة المحطات ',
    STATION_COUNT: 'عدد المحطات',
    TOLL_BALANCE: 'رصيد البوبات',
    FUEL_BALANCE: 'رصيد البنزين',
    STORE_BALANCE: 'رصيد المتاجر',
    BALANCE: 'الرصيد',
    STATION_PROVIDER_SUPERVISOR_NAME: 'اسم مسؤول شبكة المحطات',
    STATION_PROVIDER_SUPERVISOR_PHONE: 'رقم هاتف مسؤول شبكة المحطات',
    STATION_PROVIDER_SUPERVISOR_EMAIL: 'بريد مسؤول شبكة المحطات',
    MARKER_IMG: 'صورة ',
    LOGO: 'الشعار',
    INVALID_EMAIL: 'صيغة عنوان البريد الإلكتروني غير صالحة',
    INVALID_PHONE: 'رقم هاتف غير صالح',
    THIS_USER_NAME_IS_ALREADY_TAKEN: 'اسم المستخدم مستخدم بالفعل ',
    THIS_PHONE_NUMBER_IS_ALREADY_TAKEN: 'هذا الرقم مستخدم بالفعل ',
    FUEL_NAME_AR: 'اسم الوقود باللغة العربية',
    FUEL_NAME_EN: 'اسم الوقود باللغة الإنجليزية',
    ADD_NEW_FUEL: 'إضافة نوع وقود',
    PASSWORDS_NOT_MATCHED: 'كلمات السر لا تتطابق',
    PASSWORD_CONFIRMATION: 'تأكيد كلمة المرور',
    BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT: 'الرصيد غير كافي لاتمام عملية التحويل',
    TRASNSEFER_DEPOSIT: 'تحويل نقدي',
    ACCOUNT_TYPE: 'نوع الحساب',
    DEBIT: 'سحب',
    CREDIT: 'ايداع',
    CORPORATE_DEBIT: 'ايداع',
    CORPORATE_CREDIT: 'سحب',
    TRASEFER_DIRECRTION: 'نوع العملية',
    S: 'رقم الشركة التسلسلي',
    REFERNCE_ID: 'الرقم المرجعي للوحدة',
    REFERNCE_ID_TYPE: 'نوع الرقم المرجعي',
    TRANSFER: 'تحويل مالي',
    PICK: 'اختر',
    ADD_NEW_DRIVER: 'إضافة سائق جديد',
    ACC_TYPE_NOT_FOUND: 'هذا الحساب غير مفعل برجاء ايداع المال للتفعيل',
    VEHICLE_TYPE_NAME_AR: 'اسم نوع المركبة باللغة العربية',
    VEHICLE_TYPE_NAME_EN: 'اسم نوع المركبة باللغة الإنجليزية',
    DIMENSIONS_DEPTH: 'الأبعاد: العمق',
    DIMENSIONS_WIDTH: 'الأبعاد: العرض',
    DIMENSIONS_HEIGHT: 'الأبعاد: الطول',
    TOLL_MULTIPLIER: 'مضاعف الرسوم',
    TOLL_WEIGHT_MODIFIER: 'معدل الوزن',
    METER: 'م',
    ADD_NEW_STATION_PROVIDER: 'إضافة شبكة محطات جديدة',
    ADD_NEW_PRODUCT_TYPE: 'إضافة نوع منتج جديد',
    PRODUCT_NAME_AR: 'اسم المنتج باللغة العربية',
    PRODUCT_NAME_EN: 'اسم المنتج باللغة الإنجليزية',
    KG_CUBIC_METER: 'كجم / م٣',
    STATION_ID: 'رقم المحطة',
    STATION_PROVIDER_ID: 'رقم شبكة المحطات',
    PRODUCT_ID: 'رقم المنتج',
    PRICING_ID: 'رقم التعريفة',
    VEHICLE_TYPE_ID: 'رقم نوع المركبة',
    ID: '#',
    EDIT_FUEL: 'تعديل بيانات الوقود',
    EDIT_CORPORATE: 'تعديل بيانات الشركة',
    ADD_NEW_PRICING: 'إضافة تعريفة جديدة',
    ASSIGN_DRIVER_TO_VEHICLE: 'تعيين سائق للمركبة',
    INCOME: 'المدفوعات',
    INCOMEMONEY: 'الإيرادات',
    DOWNLOAD_CSV: 'تحميل CSV',
    DOWNLOAD_XLSX: 'تحميل XLSX',
    CHOOSE_STATION: 'اختر المحطة',
    CHOOSE_DRIVER: 'اختر السائق',
    CHOOSE_CORPORATE: 'اختر الشركة',
    KG: 'كجم',
    EDIT_STATION: 'تعديل محطة',
    NAME_AR: 'الإسم باللغة العربية ',
    NAME_EN: 'الإسم باللغة الإنجليزية ',
    ADD_NEW_STAFF: 'إضافة موظف جديد',
    WEAK_PASSWORD: ' كلمة السر يجب ان تحتوي على الأقل  على رقم و حرف كبير و اخر صغير بالإضافة إلى رمز خاص و لا يقل طولها عن (8)',
    INVALID_PASSWORD_LENGTH: 'كلمة السر يجب أن لا يزيد طولها عن (20)',
    STAFF_NAME: 'اسم الموظف',
    STAFF_ROLE: 'الموظف',
    ROLE: 'الوظيفة',
    CHANGE: 'تغيير',
    CHANGE_PIN_CODE: 'تغيير الرقم السري',
    CORPORATE_TITLE: 'شركة',
    CORPORATE_DETAILS: 'تفاصيل الشركة',
    DRIVER_COUNT: 'عدد السائقين',
    VEHICLE_COUNT: 'عدد المركبات',
    BALANCE_DETAILS: 'تفاصيل الحساب',
    OPENING_BALANCE: 'الرصيد المبدئي',
    REPLENISHMENT_AMOUNT: 'مبلغ إعادة التعبئة',
    OTHER_DETAILS: 'تفاصيل اخرى',
    NUMBER_OF_TRANSACTIONS: 'عدد العمليات',
    FUEL_VOLUME: 'إجمالي الوقود',
    STATION_TITLE: 'محطة',
    STATION_PROVIDER_TITLE: 'شبكة محطات',
    MONTHLY_TRENDS: 'المؤشرات الشهرية',
    NUMBER_OF_DRIVERS: 'عدد السائقين',
    NUMBER_OF_CORPORATES: 'عدد الشركات',
    MONTHLY_FUEL_AMOUNT: 'قيمة الوقود المستهلك (EGP)',
    MONTHLY_FUEL_VOLUME: 'كمية الوقود المستهلك (لتر)',
    MONTHLY_FUEL_VOLUME_EGP: 'كمية الوقود المستهلك (EGP)',
    MONTH_OVER_MONTH: 'معدلات النمو',
    FUEL_CONSUMPTION_VALUE: 'استهلاك الوقود',
    FUEL_ANALYSIS_BREAKDOWN: 'تحليل استهلاك الوقود',
    FUEL_VOLUME_BREAKDOWN: 'تحليل الكمية المستهلكة',
    FUEL_AMOUT_BREAKDOWN: 'تحليل القيمة المدفوعة',
    FUEL_ANALYSIS_DETAILS: 'تفاصيل استهلاك الوقود',
    STATISTICAL_ANALYSIS: 'تحليل الإحصائات',
    TOP_5_DRIVERS: 'تحليل استهلاك اعلى 5 سائقين',
    AREA_ANALYSIS: 'المناطق',
    STATION_ANALYSIS: 'المحطات',
    AREA_STATION_ANALYSIS: 'تحليل المناطق و المحطات',
    BALANCES: 'الأرصدة',
    OVER_ALL_BALANCE: 'الرصيد الشامل',
    NET_BALANCE: 'صافي الرصيد',
    CORPORATE_NAME: 'اسم الشركة',
    DRIVERS_FUEL_TOTAL_BALANCES: ' رصيد الوقود للسائقين',
    VEHICLES_FUEL_TOTAL_BALANCES: ' رصيد الوقود للمركبات',
    UNUSED_BALANCE: 'الرصيد غير المستخدم',
    STAFF: 'بيانات الموظفين',
    UNASSIGN: 'ازالة',
    ASSIGNED_DRIVERS: ' السائقين المعينين للمركبة',
    WITHDRAW: 'دفع',
    FUEL_ATTENDANT_NAME: 'اسم مسئول المحطة',
    PICK_FILE: 'اختر ملف',
    WALLET_BALANCE: 'رصيد المحافظ',
    TOTAL_BALANCE: 'الرصيد الإجمالي',
    AVIALABLE_BALANCE: 'الرصيد المتاح',
    COORDINATES: 'الإحداثيات',
    INVALID_COORDINATES: 'هذه الإحداثيات غير صحيحه',
    UPLOAD: 'رفع ملف',
    UPLOAD_VEHICLE_BALANCE: 'رفع ملف تحويل مالي للمركبات',
    UPLOAD_DRIVER_BALANCE: 'رفع ملف تحويل مالي للسائقين',
    UPLOAD_ADD_NEW_VEHICLE: 'رفع ملف المركبات',
    UPLOAD_ADD_NEW_DRIVER: 'رفع ملف السائقين',
    CONSUMED_BALANCE: 'رصيد مستخدم',
    REMAINING_BALANCE: 'رصيد متبقي',
    EDIT_VEHICLE: 'تعديل بيانات المركبة',
    ODOMETER_READING: 'قراءة العداد (KM)',
    FUEL_CONSUMPTION: 'استهلاك الوقود (باللتر)',
    VIEW_IMAGE: 'عرض',
    RELATED_IMAGES: 'الصور الملحقة',
    ODOMETER_IMG: 'صورة العداد',
    PUMP_IMG: 'صورة مضخة البنزين',
    NO_IMG: 'لا يوجد صورة',
    NO_VEHICLES_SELECT_ANTHER_CORPORATE: 'لا يوجد مركبات إختر شركة أخرى',
    NO_DRIVERS_SELECT_ANTHER_CORPORATE: 'لا يوجد سائقين إختر شركة أخرى',
    NO_STATIONS_SELECT_ANTHER_STATION_PROVIDER: 'لا يوجد محطات إختر شبكة محطات أخرى',
    FINANCIAL_VALUE: 'القيمة المالية ',
    FUEL_QUANTITY: ' كمية الوقود ',
    TOTAL: 'إجمالي',
    RESET_PIN_CODE: 'إعادة تعيين رمز التحقيق',
    INVALID_PIN_CODE: 'رمز التحقق غير صالح ',
    INVALID_PIN_CODE_FOUR_NUMBERS: 'رمز التحقق غير صالح يجب أن يكون أربعة أرقام ',
    NEW_PIN_CODE: 'رمز التحقق الجديد',
    PRE_PAID: 'مسبق الدفع',
    POST_PAID: 'مؤخر الدفع',
    CREDIT_LIMIT: 'الحد الإئتماني',
    MONTHLY: 'شهري',
    WEEKLY: 'أسبوعي',
    BILLING_PERIOD: 'فترة الفاتورة',
    AMOUN_THRESHOLD: 'الحد الأدنى للمبلغ',
    MAX_AMOUNT_THRESHOLD: 'الحد الأقصي للمبلغ',
    THRESHOLD: 'الحد الأدنى',
    ASSIGNED_VEHICLES: 'المركبات المعينة للسائق',
    CHOOSE_Date: 'اختر التاريخ',
    EDIT_DRIVER: 'تعديل بيانات السائق',
    INVOICES: 'الفواتير',
    PERIOD_START: 'بداية الفاتورة',
    PERIOD_END: 'نهاية الفاتورة',
    UNPAID: 'غير مدفوع',
    PAID: 'مدفوع',
    PARTIALLY_PAID: 'مدفوعة جزئيًا',
    AMOUNT_ALREADY_PAID: 'مبلغ مسبق الدفع',
    TOTAL_INVOICE: 'إجمالي الفاتورة',
    AMOUNT_PAID: 'إجمالي المدفوعات',
    TOTAL_DUE: 'إجمالي المستحق',
    TOTAL_BALANCES: 'إجمالي الأرصدة',
    AMOUNT_REMAINING: 'المبلغ المتبقي',
    TOTAL_CONSUMPTION: 'إجمالي الإستهلاك',
    MONTH_TOTAL: 'إجمالي الشهر',
    PAY: 'ادفع',
    PAY_INVOIVE_NUMBER: 'دفع فاتورة رقم',
    INVOICE_NUMBER: 'فاتورة رقم',
    STORE_TRANSACTIONS: 'معاملات المتاجر',
    STORE_PRODUCTS: 'منتجات',
    ADD_STORE_PRODUCT: 'إضافة منتج',
    EDIT_STORE_PRODUCT: 'تعديل بيانات المنتج',
    CASH_WALLET: 'محفظة عامة',
    TOP_5_CORPPRATES: 'تحليل استهلاك اعلي 5 شركات',
    TOTAL_DRIVERS: 'عدد السائقين',
    TOTAL_COLLECTED: 'إجمالي الإيداع',
    STARTING_BALANCE: 'الرصيد الأصلي',
    REPLENISH_AMOUNT: 'الحد الأدني للرصيد',
    REPLENISH_AMOUNT_LESS_THAN_STARTING_BALANCE: 'مبلغ إعادة التعبئة يجب أن يكون أقل من الرصيد المبدئي أو يساويه',
    KM_MIN_LESS_THAN_KM_MAX: 'الحد الادني للمسافة يجب أن يكون أقل من الحد الأقصى للمسافة أو يساويها',
    REFILL_VEHICLES: 'إعادة شحن أرصدة المركبات',
    REFILL_DRIVERS: 'إعادة شحن أرصدة السائقين',
    NOT_ENOUGH_CREDIT: 'لا يوجد رصيد كافي لإتمام العملية',
    TRANSACTIONS_DATA_ENTRY: 'ادخال بيانات المعاملات',
    PUMP_READING: 'قراءة المضخة',
    PUMP_READING_REVIEW_DATE: 'تاريخ مراجعة قراءة المضخة',
    STATIONS_ACCOUNTS: 'حسابات المحطات',
    CORPORATES_ACCOUNTS: 'حسابات الشركات',
    ACCOUNTING: 'الحسابات',
    PRINTING: 'الطباعة',
    PRINTER: 'يوجد طابعة',
    NO_PRINTER: 'لا يوجد طابعة',
    COMMISSION_FEE: 'رسوم العمولة',
    FEE_PERCENTAGE_RATE: 'نسبة العمولة',
    FLAG_RATE: 'خصم مباشر',
    FLAG_RATE_OPTION: 'الخصم',
    FINANCE_FEE: 'رسوم التمويل',
    NO_NEGATIVE_NUMBER: 'هذا الحقل لا يقبل القيم السالبة',
    TOTAL_FEES: 'إجمالي الرسوم',
    MORE_THAN_ZERO: 'هذا الحقل يقبل قيم أكبر من الصفر فقط',
    IS_DEMO: 'تجريبي',
    NOT_DEMO: 'إفتراضي',
    IS_READING: 'جاري المراجعه',
    UNREAD_YET: ' غير مقروءة',
    NO_DRIVERS_FOR_THIS_CORPORATE: 'لا يوجد سائقين لهذه الشركة',
    NO_VEHICLES_FOR_THIS_CORPORATE: 'لا يوجد مركبات لهذه الشركة',
    NO_STATION_PROVIDERS_FOR_THIS_CORPORATE: 'لا يوجد شبكات محطات لهذه الشركة',
    ALL_TRANSACTIONS: 'جميع المعاملات',
    NEW_TRANSACTIONS: 'معاملات جديده',
    SHIFT_START_TIME: 'وقت بدء الوردية',
    NUMBER_OF_SHIFTS_PER_DAY: 'عدد الورديات في اليوم',
    IS_EXTERNAL_FUEL_TRANSACTION: 'هل هذه المعاملة خارجية',
    EXTERNAL: 'خارجية',
    THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS: 'هذاالحقل لا يقبل إلا أرقام صحيحة',
    THIS_INPUT_ACCEPTED_ONLY_NUMBERS: 'هذاالحقل لا يقبل إلا أرقام',
    UPDATE_ODOMETER_READING: 'تعديل قراءة العداد (KM)',
    UNBILLED: 'إعفاء حسابي',
    THIS_PLATE_NUMBER_IS_ALREADY_TAKEN: 'رقم اللوحة أو رقم الشاسيه أو الكود مستخدم بالفعل',
    THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN: 'اسم المستخدم أو رقم الهاتف مستخدم بالفعل',
    THIS_CODE_ALREADY_TAKEN: 'هذا الكود مستخدم بالفعل',
    TOTAL_BILLED: 'مدفوع',
    ACCESS_SP: 'تحديد شبكات المحطات التي يمكن التعامل معها',
    ACCESSABLE_STATION_PROVIDERS: 'شبكات المحطات التي يمكن التعامل معها',
    UNBILLED_STATION_PROVIDERS: 'شبكات محطات لا يتم المحاسبة معها',
    TRANSACTIONS_TO_BE_REVIEWED: 'معاملات للمراجعة',
    DIFFERENCE_RATIO: 'نسبة الإختلاف',
    RATIO_OF_INCREASE: 'نسبة الزيادة',
    VEHICLE_SUBSCRIPTIONS: 'اشتراك المركبات',
    VEHICLE_SUBSCRIPTION: 'اشتراك المركبة',
    MIMIMUM_FEE: 'الحد الأدنى للعمولة',
    AMOUNT_DUE_PER_VEHICLE: 'المبلغ المستحق لكل مركبة',
    RENEWAL_DATE: 'تاريخ التجديد',
    SALES: 'المبيعات',
    TOTAL_UNBILLED: 'غير مدفوع',
    TOTAL_PAYMENTS: 'إجمالي المبيعات',
    CLAIM_AMOUNT: 'المبلغ المطلوب',
    ODOMETER_IMAGES: 'صوره العداد',
    ODOMETER_READING_STATUS: 'حالة قراءة العداد',
    PAY_VEHICLE_SUBSCRIPTION: 'دفع اشتراك',
    COMMENT: 'التعليق',
    SHOW_REPORTS: 'اظهار التقارير',
    HIDE_REPORTS: 'اخفاء التقارير',
    REPORTS: 'التقارير',
    MULTIPLE: 'متعدد',
    NO_VEHICLES: 'لا يوجد مركبات',
    TRANSACTION_DETAILS: 'تفاصيل المعاملة ',
    CONSUMTION_RATIO: 'معدل الإستهلك',
    PAYMENT_DETAILS: 'تفاصيل الحساب',
    TRANSACTION_NUMBER: 'رقم المعاملة',
    NO_DRIVERS: 'لا يوجد سائقين',
    ADVANCED_USER: 'مستخدم متقدم',
    NOT_ADVANCED_USER: 'مستخدم غير متقدم',
    USER_TYPE: 'نوع المستخدم',
    PIN_CODE: 'رمز التحقق',
    CODE_FOR_VEHICLE: 'رمز السيارة',
    TRANSFER_TO_DRIVER: 'تحويل مالي لسائق',
    TRANSFER_TO_VEHICLE: 'تحويل مالي لمركبة',
    LOGIN_TRACKING: 'سجل الدخول',
    STATION_PROVIDER_FEE: 'رسوم شبكات المحطات',
    PIN_CODE_FOR_CORPORATE: 'الرقم السري',
    CONFIRM_PIN_CODE_FOR_CORPORATE: ' تأكيد الرقم السري',
    CASH: 'كاش',
    TRANSFER_METHOD: 'تحويل بنكي',
    PAYMENT_METHOD: 'طريقة الدفع',
    REF_NUMBER: 'رقم التحويل',
    TO_VEHICLE: 'لمركبة',
    TO_DRIVER: 'لسائق',
    PAYMENT_TABLE: 'تفاصيل المدفوعات',
    NO_PAYMENT: 'لا توجد مدفوعات',
    CORPORATE_GROUPS: 'مجموعة الشركات',
    CORPORATE_GROUP_NAME: 'اسم مجموعة الشركات',
    CORPORATES_GROUP: 'مجموعة شركات',
    CONFIRM_REVIEW: 'تأكيد المراجعة',
    REVIEW_DONE: 'تم مراجعتها',
    TO_REVIEW: 'للمراجعة',
    IS_ACTIVE: 'نشط',
    NOT_ACTIVE: 'غير نشط',
    CONSUMPTION_BENCHMARK: 'معيار الإستهلاك',
    POTENTIAL_LOSS: 'خسارة محتملة',
    REGHARGE_BALANCE: 'شحن رصيد',
    AMOUNT_MUST_BE_LESS_THAN_UNUSED_BALANCE: 'يجب أن يكون المبلغ أقل من أو يساوي الرصيد الغير مستخدم',
    DISTANCE_TRAVELED: 'المسافة المقطوعة (كم)',
    DAILY_REPLENISH_TIME: 'وقت التجديد اليومي',
    STARTING_SHIFT_PATTERN: 'يجب أن تكون علي هذا النمط (00:00)',
    NO_MINUTES: 'الدقائق يجب أن تساوي أصفار',
    INVALID_USERNAME: 'اسم المستخدم غير صالح',
    USE_SHIFTS: 'يوجد ورديات',
    NO_SHIFTS: 'لا يوجد ورديات',
    SHIFTS: 'ورديات',
    NUMBERS: 'عبارة عن أرقام',
    CORPORATES_DEPOSIT: 'إضافة رصيد للشركات',
    STATION_PROVIDERS_DEPOSIT: 'إضافة رصيد للمحطات',
    NEXT: 'التالي',
    PREVIOUS: 'السابق',
    CURRENT_BALANCE: 'الرصيد الحالي',
    OPERATIONS: 'العمليات',
    USE_LITERS: 'تستخدم اللترات',
    NOT_USE_LITERS: 'لا تستخدم اللترات',
    LITERS: 'اللترات',
    CANT_READ: 'لم أتمكن من قراءتها',
    EXCEED_VEHICLE_CAPACITY: 'هذا المبلغ يتجاوز سعة المركبة',
    ARE_YOU_SURE_YOU_WANT_TO: 'هل أنت متأكد أنك تريد',
    ADDING_PAYMENT_TO: 'إضافة رصيد إلى',
    WITHDRAW_BALANCE_FROM: 'سحب رصيد من',
    EQUAL: 'بقيمة',
    ALL_VEHICLES_ACCESS: 'السائق غير مربوط بالمركبة',
    ALL_VEHICLES_NOT_ACCESS: 'السائق مربوط بالمركبة',
    ODOMETER: 'العداد',
    IS_ODOMETER_CAMERA_ENABLED: 'تستخدم كاميرا لقراءة العداد',
    ODOMETER_CAMERA_NOT_ENABLED: 'لا تستخدم كاميرا لقراءة العداد',
    MANAGER: 'مدير',
    ACCOUNTANT: 'محاسب',
    NEED_REVIEW_AGAIN: 'بحاجة إلى المراجعة مرة أخرى',
    NO_COMMENT: 'لا تعليق',
    ODOMETER_FIRST: 'الأولوية لعداد الأمتار',
    ODOMETER_NOT_FIRST: 'ليست الأولوية لعداد الأمتار',
    HR: 'موظف الموارد البشرية',
    READING: 'جاري القراءة',
    CANNOT_BE_ZERO: 'لا يجب أن يساوي صفر',
    CONSUMPTION_MEASUREMENT: 'حساب استهلاك الوقود',
    KM_PER_LITERS: 'كم / لتر',
    LITERS_PER_HUNDRED_KM: 'لتر / 100 كم',
    LITERS_PER_KM: 'لتر / كم',
    SUBSCRIPTION_COST_PER_VEHICLE: 'تكلفة الإشتراك لكل مركبة',
    EDIT_VEHICLE_STATUS: 'تعديل حالة المركبة',
    CUSTOMER: 'العميل',
    CUSTOMERS: 'العملاء',
    DAILY: 'يومى',
    REPLENISH_TIME: 'وقت التجديد',
    REPLENISH_TYPE: 'نوع التجديد',
    WEEKLY_REPLENISH_TIME: 'التجديد كل أحد الساعه 12 صباحا',
    MONTHLY_REPLENISH_TIME: 'التجديد كل  1 من الشهر الساعه 12 صباحا',
    ALLOW_NEGATIVE_BALANCE: 'السماح بالرصيد السلبي',
    NEGATIVE_BALANCE: 'رصيد سلبي',
    NEGATIVE_BALANCE_IS_ALLOWED: 'مسموح بالرصيد السلبي',
    NEGATIVE_BALANCE_NOT_ALLOWED: 'غير مسموح بالرصيد السلبي',
    BILLING_TIME: 'تاريخ بداية المحاسبة',
    ADMIN_COMMENT: 'تعليق الأدمن',
    CORPORATE_COMMENT: 'تعليق الشركة',
    VALIDITY: 'الصلاحية',
    NOT_READABLE: 'لا يمكن قراءتها',
    ACTUAL_DISTANCE: 'المسافة الفعلية (كم)',
    REASON: 'السبب',
    VALID_STATUS: 'صحيحة',
    INVALID_STATUS: 'غير صحيحة',
    INVALID_ODOMETER_READING: 'قراءة غير صحيحة',
    LOWER_THAN_PREVIOUS_ODOMETER_READING: 'أقل من القراءة السابقة',
    SAME_PREVIOUS_ODOMETER: 'نفس المسافه',
    DISTANCE_MORE_THAN_MAX: 'المسافة أكبر من الحد الأقصى',
    DISTANCE_LESS_THAN_MIN: 'المسافة أقل من  الحد الأدنى',
    AFTER_NEGATIVE: 'بداية محاسبة جديدة',
    NEGATIVE_DISTANCE: 'مسافة سلبية',
    FIRST_TRANSACTION: 'أول معاملة',
    MAXIMUM_DISTANCE: 'الحد الأقصى للمسافة',
    MINIMUM_DISTANCE: 'الحد الأدنى للمسافة',
    AVG_FUEL_CONSUMPTION: 'متوسط ​​استهلاك الوقود',
    ICON: 'أيقونة',
    KM: 'كم',
    ADJUSTED_DISTANCE_TRAVELED: 'المسافة المقطوعة المصححة  (كم)',
    ADJUSTED_FUEL_CONSUMPTION: 'استهلاك الوقود المصحح (لتر/100كم)',
    ACTUAL_FUEL_CONSUMPTION: 'استهلاك الوقود الفعلي (لتر/100كم)',
    ADD_NEW_TRANSACTION: 'إضافة معاملة جديدة',
    READINGS: 'القراءات',
    REFUND: 'التعويضات',
    REFUND_TRANSACTION: 'إرداد معاملة',
    REFUND_TYPE: 'نوع الإرداد',
    CORRECT_AMOUNT: 'المبلغ الصحيح',
    CANT_BE_MORE_THAN_THE_TRANSACTION_AMOUNT: 'لا يمكن أن يكون أكبر من مبلغ المعاملة',
    VARIANCE_TRANSACTION: 'معاملة خاطئة',
    DUPLICATE_TRANSACTION: 'معاملة مكررة',
    CONFIRM_IS_NOT_DUPLICATED_TRANSACTIONS: 'التأكد من انها معاملات متكررة',
    DUPLICATE_TRANSACTIONS: 'أرقام المعاملات المكررة',
    DUPLICATE_TRANSACTIONS_DATA: 'المعاملات المكررة',
    BASE_TRANSACTIONS_DATA: 'المعاملة الأساسية',
    CORRECTION_VALUE: 'قيمة الزيادة',
    VALUE_CORRECTION: 'قيمة التصحيح',
    REFUND_IT: 'إرداد',
    REFUND_THIS_TRANSACTION: 'إرداد هذه المعاملة',
    THIS_TRANSACTION_NOT_ALLOWED_TO_BE_REFUNDED: 'غير مسموح لهذة العملية بالتعويض',
    ENETER_THREE_DIGITS_TO_SEARCH: ' الرجاء إدخال 3 أرقام على الأقل للبدء في البحث',
    ENETER_SERIAL_NUMBER_MINIMUM_LENGTH_THREETEEN_DIGITS_TO_SEARCH: ' الرجاء إدخال رقم التسلسل المكون من 13 رقم على الأقل للبدء في البحث',
    VARIANCE_STATUS: 'حالة الإختلاف',
    REFUNDED_TRANSACTION: 'معاملة الإرداد',
    NORMAL: 'عادي',
    VARIANCE: 'إختلاف',
    VARIANCE_REFUND: 'إرداد الإختلاف',
    DUPLICATED: 'مكررة',
    DUPLICATED_REFUND: 'إرداد المكررة',
    SERVICE_TYPE: 'نوع الخدمة',
    SERVICE_FEE: 'تكلفة الخدمة',
    TIPS_AMOUNT: 'قيمة الإكرامية',
    TIPS_REFUND: 'الإكرامية المستردة',
    VAT_AMOUNT: 'قيمة الضريبة',
    VAT_REFUND: 'الضريبة المستردة',
    TOTAL_SERVICE_FEE: 'مجموع تكلفة الخدمة',
    COSTS_COLUMN_CSV: 'الرسوم',
    CAN_NOT_UPDATE_PUMP_READING_THIS_TRANSACTION: 'لا يمكنك تحديث قراءة المضخة لهذه العملية',
    PIN_CODE_WRONG_LENGTH: 'يجب أن يتكون رمز التحقق من عدد أرقام = ',
    FRAUD: 'إحتيال',
    FRAUD_SENTENCE: 'تحديد هذه المعاملة كاحتيال',
    STATION_PERCENTAGE: 'نسبة المحطة  (%)',
    THIS_INPUT_CANT_BE_OVER_HUNDRED: 'يجب أن تكون القيمة 100 أو أقل ',
    THIS_INPUT_CANT_BE_OVER_THOUSAND: 'يجب أن تكون القيمة 1000 أو أقل ',
    STATION_TIPS: 'إكرامية المحطة',
    STATION_TIPS_REFUND: 'الإكرامية المستردة للمحطة',
    STATION_TIPS_VAT: 'ضريبة إكرامية المحطة',
    STATION_TIPS_VAT_REFUND: 'الضريبة المستردة لإكرامية للمحطة',
    _PER_LITER: 'إكرامية المحطة',
    _PER_LITER_REFUND: 'الإكرامية المستردة للمحطة',
    _PER_LITER_VAT: 'ضريبة إكرامية المحطة',
    _PER_LITER_VAT_REFUND: 'الضريبة المستردة لإكرامية للمحطة',
    PERCENTAGE: 'نسبة',
    PAISTRES_PER_LITER: 'قرش/لتر',
    PAISTRES: 'قروش',
    TIPS_TYPE: 'نوع الإكرامية',
    TIPS_FEE: 'قيمة الإكرامية',
    STATION_TOTAL_AMOUNT: 'مجموع تكلفة الخدمة للمحطة',
    ADVANCED_FILTERATION: 'بحث متقدم',
    CLEAR_ALL: 'مسح الكل',
    VIEW_DASHBOARD: 'الدخول ك',
    THIS_EMAIL_IS_ALREADY_TAKEN: 'هذا البريد الالكتروني مستخدم بالفعل',
    RESET_PASS_EMAIL: 'إعادة تعيين كلمة المرور',
    RESET_PASS_EMAIL_IS_SENT: 'تم إعادة تعيين كلمة المرور',
    RESTORE_TRANSACTION_IMAGE: 'استعادة صور العمليات',
    IMAGE_TYPE: 'نوع الصورة',
    RESTOR_IMAGE: 'استعادة الصورة',
    RESTORED_IMAGE_SUCCESSFULLY: 'تم استعادة الصورة بنجاح',
    VEHICLE_ODOMETER_IMAGE: 'عداد المركبة',
    STATION_PUMP_IMAGE: 'عداد الطرمبة',
    INCORRECT_USERNAME_OR_PASSWORD: 'اسم المستخدم او كلمة المرور غير صحيحة',
    FRAUD_DETECTION_FEES: 'تكلفة كشف الاحتيال',
    FRAUD_DETECTION_FEES_VAT: 'ضريبة تكلفة كشف الاحتيال',
    USERNAME_MUST_BE_EMAIL: 'اسم المستخدم يجب أن يكون إيميل',
    CHARGING_PROFILES: 'سجلات المحاسبة',
    CHARGING_PROFILE_DETAILS: 'تفاصيل سجل المحاسبة',
    CHARGING_PROFILE_TYPE: 'نوع سجل المحاسبة',
    VERSIONS: 'الإصدارات',
    VERSION: 'الإصدار',
    LAST_VERSION: 'الإصدار الأخير',
    VERSION_STATUS: 'حالة الإصدار',
    VERSION_CREATOR: 'تم الإنشاء ب',
    VERSION_REVIEWER: 'تم المراجعة ب',
    VERSION_CONFIRMER: 'تم التأكيد ب',
    SIGNED_OF_BY: 'تم التوقيع ب',
    INDUSTRY: 'الصناعة',
    CUSTOMER_INFO: 'بيانات العميل',
    CORPORATE_ID: 'الشركة #',
    VERSION_NUMBER: 'الإصدار #',
    CHARGING_PROFILE_ID: 'سجل الحسابى #',
    PROFILE_TYPE: 'نوع السجل',
    CONTRACT: 'العقد',
    REVIEW: 'مراجعة',
    CONFIRM: 'تأكيد',
    SIGNE_OFF: 'توقيع',
    INACTIVE: 'غير فعال',
    ACTIVATED: 'فعال',
    UPLOAD_CONTRACT: 'تحميل العقد',
    CHARGING_FEES: 'الرسوم',
    VEHICLE_FEES: 'رسوم المركبة',
    SUBSCRIPTION_FEES: 'رسوم الإشتراك (جنيه)',
    NFC_FEES: 'رسوم NFC  (جنيه)',
    INSTALLATION_FEES: 'رسوم التثبيت (جنيه)',
    NFC_REPLACEMENT_COST: 'تكلفة استبدال NFC  (جنيه)',
    TRANSACTION_FEES: 'رسوم المعاملة',
    FINANCING_FEES: ' رسوم التمويل (%)',
    TIPS_FEES: 'رسوم الإكرامية',
    EXTERNAL_STATION: 'محطة خارجية (%)',
    INTERNAL_STATION: 'محطة داخلية (%)',
    EXTERNAL_STATION_FEES: 'رسوم محطة خارجية',
    INTERNAL_STATION_FEES: 'رسوم محطة داخلية',
    EXTERNAL_STATION_FEES_TIPS: 'رسوم المحطة الخارجية (الإكرامية)',
    EXTERNAL_STATION_SPECIAL_FEES: 'السعر الخاص للمحطة الخارجية',
    FRUAD_FEES: 'رسوم الاحتيال ',
    INVOICING_DETAILS: 'تفاصيل الفاتورة',
    INVOICE_PERIOD: 'فترة الفاتورة',
    TAX_ID: 'الرقم الضريبي',
    BIWEEKLY: 'نصف إسبوعي',
    TEN_DAYS: 'عشرة أيام',
    UPLOAD_PIC_FILE: 'رفع ملف أو صورة',
    CHARGED: 'مدفوع',
    PAID_TRIAL: 'تجربة مدفوعة',
    FREE_TRIAL: 'تجربة مجانية',
    CREATED: 'تم الإنشاء',
    REVIEWED: 'تم المراجعة',
    SIGNED_OFF: 'تم التوقيع',
    CONFIRMED_STATUS: 'تم التأكيد',
    ACTIVE_STATUS: 'نشط',
    EXPIRED_STATUS: 'منتهي',
    PROFILE_STATUS: 'حالة السجل',
    TYPE_OF_CHARGING_PROFILE: 'نوع السجل الحسابي',
    END_DATE: 'تاريخ الإنتهاء',
    THIRTY_DAYS: '30 يوم',
    FIFTEEN_DAYS: '15 يوم',
    PERIOD: 'فترة',
    SELECT_PERIOD: 'حدد الفترة',
    AFTER_TRIAL: 'بعد التجربة',
    REPEAT_EXPERIMENT_AND_DETERMINE_FEES: 'إعادة التجربة وتحديد الرسوم',
    STOP_EXPERIMENT: 'توقف التجربة',
    REJECTION_REASON: 'سبب الرفض',
    CANNOT_BE_MORE_THAN_2000: 'لا يمكن ان يكون اكبر من 2000',
    CANNOT_BE_MORE_THAN_100: 'لا يمكن ان يكون اكبر من 100',
    VERSION_REJECTOR: 'تم الرفض ب',
    EXCEEDED_MAXIMUM_IMAGES: 'تجاوزت الحد الأعلى للصور (10 صور)',
    EXCEEDED_MAXIMUM_FILES: 'تجاوزت الحد الأعلى للملفات (3 ملفات)',
    TRIAL_DURATION: 'مدة التجربة',
    PENDING_ADJUSTMENTS: 'بحاجة للتعديل',
    REVIEW_NEEDED: 'المراجعة مطلوبة',
    NEEDS_REVIEW: 'بحاجة للمراجعة',
    NEEDS_CONFIRMATION: 'بحاجة للتأكيد',
    NEEDS_SIGNOFF: 'بحاجة للتوقيع',
    BANK_NAME: 'اسم البنك',
    DEPOSIT_TYPE: 'نوع الإيداع',
    ENTERED_AMOUNT: 'المبلغ المدخل',
    ACTUAL_AMOUNT: 'المبلغ الفعلي',
    REASON_ACTUAL_AMOUNT_IS_DIFFERENT_FROM_ENTERED: 'سبب اختلاف المبلغ الفعلي عن المبلغ المدخل',
    DIFFERENCE_REASON: 'سبب الاختلاف',
    DUPLICATED_TRANSACTIONS: 'معاملات مكررة',
    CHOOSE_TRANSACTION: 'اختر المعاملة',
    RECOVERY_STATUS: 'حالة الاسترداد',
    RESTORED: 'تم الاسترداد',
    CONFIRM_REVIEWING: 'تأكيد المراجعة',
    DUPLICATE_TRANSACTION_DETAIL: 'تفاصيل تكرار المعاملة',
    SUSPECT: 'اشتباه تكرار',
    DUPLICATE: 'مكرر',
    BANK_TRANSFER: 'تحويل بنكي',
    BANK_DEPOSIT: 'ايداع بنكي',
    CHEQUE: 'شيك',
    VEHICLES_SUBSCRIPTION: 'إشترك المركبات',
    PAYMENT_ID: 'رقم الدفع',
    DEPOSIT_REQUESTES: 'طلبات إيداع',
    MISSING_AMOUNT: 'لابد من إدخال المبلغ لهذه المعاملة قبل تأكيدها',
    SHOW_BALANCE: 'اظهار الرصيد',
    HIDE_BALANCE: 'اخفاء الرصيد',
    AUTO_REFUND: 'استرداد تلقائي',
    REFUNDED: 'الإسترداد',
    ALREADY_USED_EMAIL: 'عنوان البريد الإلكتروني مستخدم بالفعل',
    PASSWORD_CHANGED_SUCCESSFULLY: 'تم تغيير كلمة المرور بنجاح',
    FORCE_LOGOUT: 'تسجيل خروج إجباري',
    FORCE_LOGOUT_DONE: 'تم تسجيل الخروج بنجاح',
    DEPOSIT_CONFIGURATION: 'اعدادات الإيداع',
    ALERT: 'تنبيه',
    CORPORATE_GROUP_CONFIGURATION_WILL_BE_APPLAIED_FOR_ALL_CORPORATES_WITHIN_THE_GROUP: 'سيتم تطبيق إعدادات المجموعة تلقائيًا على جميع الشركات ضمن المجموعة.',
    SHOW_STATION: 'اظهار المحطة',
    DONT_SHOW_STATION_FOR_ALL_DRIVERS: 'لا تظهر المحطة لجميع السائقين',
    SHOW_STATION_FOR_ALL_DRIVERS: 'تظهر المحطة لجميع السائقين',
    MOBILE_IMAGE: 'صورة جوال',
    NO_LITERS: 'لا يوجد لترات',
    FRAUD_REASON: 'سبب الإحتيال',
    FRAUD_TYPE: 'نوع الأحتيال',
    DEPOSIT_DISTRIBUTION: 'توزيع الإيداعات',
    E_INVOICES: 'الفواتير',
    INVOICE_DATE: 'InvDate',
    CONTACT_NAME: 'ContactName',
    PAYMENT_TERM: 'شرط الدفع',
    INVOICE_TYPE: 'اليومية',
    MOVEMENT_TYPE: 'نوع الحركة',
    REFERENCE_NUMBER: 'ReferenceNo',
    ITEM_NAME: 'اسم الصنف',
    INVOICE_DESCRIPTION: 'الوصف',
    QUANTITY: 'Qty',
    DISCOUNT_BEFORE_TAX_TYPE: 'DiscountBeforeTaxType',
    DISCOUNT_BEFORE_TAX: 'DiscountBeforeTax',
    UNIT: 'وحدة القياس',
    TAX_GROUP: 'TaxGroup',
    EXCHANGE_RATE: 'سعر الصرف',
    INVOICE_NUMBER_EN: 'InvoiceNumber',
    CORPORATE_GROUP_HAS_ACTIVE_DEPOSIT: 'لا يمكن التعديل: توجد طلبات إيداع نشطة لهذه المجموعة !!!',
    CORPORATE_UNDER_THIS_CORPORATE_GROUP_HAS_ACTIVE_DEPOSIT: 'لا يمكن التعديل: توجد طلبات إيداع نشطة لشركة ضمن هذه المجموعة !!!',
    CORPORATE_HAS_ACTIVE_DEPOSIT: 'لا يمكن التعديل: توجد طلبات إيداع نشطة لهذه الشركة !!!',
    CORPORATE_BUNDLE: 'الباقة',
    BASIC_BUNDLE: 'أساسي',
    SAVING_PLUS_BUNDLE: 'توفير بلس',
    SAVING_PRO_BUNDLE: 'توفير برو',
    AI_TRIAL: 'تجربة الذكاء الإصطناعي',
    AI_TRIAL_ACTIVATE: 'تفعيل تجربة الذكاء الإصطناعي',
    TOP_MANAGER: 'مدير مع وصول إلى الذكاء الاصطناعي',
    THE_SERIAL_NUMBER: 'الرقم التسلسلى',
    SERIAL_NUMBER: 'رقم التسلسل',
    THE_VERSION_NUMBER: 'نسخه الأصدار',
    POS: 'POS',
    APPOINTMENT_STATUS: 'حالة التعيين',
    ALL_STATIONS: 'جميع المحطات',
    NOT_ASSIGNED: 'لا يوجد',
    ONE_STATION: 'محطة واحدة',
    POS_ID: 'الرقم التعريفى',
    ALLOW_SKIP: 'السماح بالتخطي',
    NO_SKIP: 'بدون تخطي',
    ALLOW_AUTO_SKIP: 'السماح بالتخطي التلقائي',
    FRAUD_FEE_CONGIRATION: 'اعدادات رسوم الأحتيال',
    PIASTERS_PER_LITER: 'قروش/لتر',
    PERCENTAGE_FROM_AMOUNT: 'نسبة من المبلغ',
    STATION_TIPS_PER_LITER: 'إكرامية المحطة (قروش/لتر)',
    FIXED_AMOUNT: 'مبلغ',
    TAX_RECORD: 'السجل الضريبي',
    DISTINCTIVE_TAX_NUMBERS: 'الأرقام الضريبية المميزة',
    PIN_CODE_LENGTH: 'طول رمز التحقيق',
    ENTER_VALID_AMOUNT_UP_TO_2_DECIMAL: 'أدخل مبلغًا صحيحًا بحد أقصى رقمين عشريين.',
    INTERNAL_TRANSFER_FOR_THE_SAME_COMPANY: 'تحويل داخلى لنفس الشركه',
    EXCEPTION_DEPOSIT: 'اضافه استثناء',
    EXCEPTION_REFUND: 'رد استثناء',
    DEPOSIT_DUE_TO_TECHNICAL_ISSUE: 'ايداع بسبب مشكله تقنيه',
    USERNAME_ALPHA_NUMERIC_ONLY: 'اسم المستخدم يجب أن يحتوي على أحرف صغيرة وأرقام فقط.',
    NAME_ALPHA_NUMERIC_SPECIAL_CHAR_ONLY: 'يجب أن يحتوي الاسم على أحرف وأرقام ورموز خاصة فقط.',
    CODE_MUST_UNIQUE: 'يجب أن يكون كود السائق فريدًا ضمن الشركة.',
    THIS_USERNAME_IS_ALREADY_TAKEN: 'هذا الاسم مستخدم بالفعل.',
    THIS_PHONE_IS_ALREADY_TAKEN: 'هذا الهاتف مستخدم بالفعل.',
    VEHICLE_MODELS: 'موديلات المركبات',
    VEHICLE_MODEL: 'موديل المركبة',
    VEHICLE_BRAND_NAME: 'العلامة التجارية للمركبة',
    VEHICLE_BRAND: 'العلامة التجارية',
    VEHICLE_BRAND_NAME_ALREADY_EXISTS: 'هذا الاسم موجود بالفعل في هذه العلامة التجارية!',
    ADD_VALID_MODEL_NAME: 'أدخل اسم موديل صالح',
    VEHICLE_BRANDS: 'العلامات التجاريه',
    BRAND_NAME: 'العلامة التجارية',
    ADD_VALID_BRAND_NAME: 'يرجى إدخال اسم علامه التجاريه للسيارة صالح.',
    BRAND_NAME_ALREADY_EXISTS: 'هذه علامه التجاريه موجودة بالفعل.',
    EDIT_BRAND: 'تعديل العلامة التجارية',
    TAX_IDS: 'السجلات الضريبيه',
    TAX_ID_NUMBER: 'رقم التسجيل الضريبي',
    ID_NUMBER: 'الرقم التسلسلي',
    SERVICES: 'الخدمات',
    CHOOSE_SERVICES: 'اختيار خدمات',
    CAR_WASH_FEE: 'رسوم غسيل السيارات',
    OIL_CHANGE_FEE: 'رسوم تغيير الزيت',
    CAR_SERVICES: 'خدمات السيارات',
    CHOOSE_FROM_SERVICES: 'اختر من الخدمات',
    CAR_WASH: 'غسيل المركبات',
    OIL_CHANGE: 'تغيير زيت',
    CHOOSE_AT_LEAST_ONE_SERVICE: 'اختر على الاقل خدمة واحدة',
    NFC_LOGS: 'سجل NFC',
    CAR_SERVICES_TRANSACTION: 'معاملات خدمات المركبات',
    MAIN_INFO: 'بيانات أساسية',
    SETTINGS: 'الاعدادات',
    SEARCH_BY_TRANSACTION_ID: 'ابحث برقم المعاملة',
    //-----------Bulk Upload------------
    THERE_ARE_ERRORS_IN_THE_FILE: 'يوجد خطأ في تحميل الملف',
    NUMBER_OF_RECORDS_WEITH_ERRORS: 'عدد السجلات التي بها خطأ:',
    UPLAOD_FILES: 'تحميل الملفات',
    NUMBER_OF_RECORDS: 'عدد السجلات:',
    NUMBER_OF_SUCCESSFUL_RECORDS_UPLOADED: 'عدد السجلات المرفوعة بنجاح :',
    NUMBER_OF_RECORDS_WEITH_ERRORS_VALIDATION_MODAL: 'عدد السجلات بها اخطاء :',
    TOTAL_NUMBER_OF_ERROS: 'اجمالي الاخطاء :',
    DISPLAY_ERRORS: 'عرض الخطأ',
    //----------------------------------
    ENTER_VALID_USERNAME: 'أدخل اسم مستخدم صحيح',
    ADD_VALID_PLATE_NUMBER: 'أدخل رقم لوحة صالح',
    MAX_LITERS: 'الحد الأقصى للتر',
    ADD_VALID_YEAR: 'يرجى إدخال سنة تصنيع صحيحة',
    MANUFACTURE_YEAR: 'سنة التصنيع',
    ADS: 'Ads',
    DELETE_ADS: 'مسح الاعلان',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ADS: 'هل أنت متأكد أنك ترغب في مسح الاعلان',
    REMOVE: 'مسح',
    UPLOAD_FILES: 'تحميل الملفات',
    UPLOAD_ADS: 'تحميل الاعلان',
    ADS_EN: 'الاعلان بالانجليزية',
    ADS_AR: 'الاعلان بالعربي',
    ADS_SORT: 'ترتيب الاعلان',
  }
}

export default dictionary
